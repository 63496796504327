import { Typography, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import "../css/About.css";
import SocialLinks from '../components/SocialLinks.tsx';

export default function AboutPage() {
    const isSmallScreen = useMediaQuery('(max-width:1200px)');
    return (
        <div>
            <Grid container sx={{ display: 'flex', justifyContent: isSmallScreen ? 'space-evenly' : 'center', alignItems: 'end', flexDirection: 'row', backgroundColor: '#090446', color: 'white', minHeight: isSmallScreen ? '50vh' : '50vh' }} id='about'>
                <Grid item xs={12} md={12} lg={8} xl={8} sx={{ display: 'flex', flexDirection: 'Column', justifyContent: 'center', alignItems: 'center', mt: isSmallScreen ? 24 : '' }}>
                    <Grid item xs={10} sx={{ justifyContent: 'center' }}>
                        <Typography variant={isSmallScreen ? 'h5' : 'h2'} sx={{ display: 'flex', flexDirection: 'row' }} >Bishal Gautam</Typography>
                        <Typography variant={isSmallScreen ? 'h6' : 'h5'} sx={{ display: 'flex', flexDirection: 'row' }} className='font-color-call-to-action'>Full Stack Developer</Typography>
                        <Typography align='justify' sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }} className='font-color-normal'>
                            I’m a passionate and versatile full-stack web developer with deep experience in building scalable, high-performance web applications. I’m proficient in technologies like C#, ASP.NET Core, React.js, and Flutter, and I’m passionate about delivering innovative solutions that improve efficiency for organizations. With additional expertise in Unity 3D development, I focus on writing clean, maintainable code and delivering custom software solutions that align with business goals. As a confident communicator with strong problem-solving abilities, I’m dedicated to consistently achieving results.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={6} lg={3} xl={3} sx={{ mt: 2, display: 'flex', justifyContent: isSmallScreen ? 'center' : 'end' }}>
                    <SocialLinks />
                </Grid>
            </Grid>
            {/* <ExperiencePage /> */}
        </div>
    );
}
