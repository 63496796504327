import React from 'react';
import { FourSquare, Commet } from 'react-loading-indicators';

export default function Loading() {
    return (
        <div className='' style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#090446',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{ transform: 'scale(1.5)' }}>
                <Commet color="#5FFBF1" size="large" text="BG" textColor="" />
            </div>
        </div>
    );
}
