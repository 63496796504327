import React from 'react';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Box from '@mui/material/Box';

interface ExperienceCardProps {
    duration: string;
    jobTitle: string;
    companyName: string;
    responsibilities: string[];
    skills: string[];
}

export default function ExperienceCard({ duration, jobTitle, companyName, responsibilities, skills }: ExperienceCardProps) {
    const isSmallScreen = useMediaQuery('(max-width:1050px)');
    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    ml: isSmallScreen ? 4 : '',
                    mt: 2,
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row'
                }}
                className='primary-color'
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: isSmallScreen ? 'start' : 'end'
                    }}
                >
                    <Typography className='font-color-normal'>{duration}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: isSmallScreen ? '' : 20
                    }}
                >
                    <Typography
                        variant='h6'
                        className='font-color-call-to-action'
                        sx={{ marginBottom: 2 }}
                    >
                        {jobTitle}
                        <span className='font-color-for-topics '>{companyName}</span>
                    </Typography>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'start',
                            gap: 1
                        }}
                    >
                        {responsibilities.map((responsibility, index) => (
                            <Typography
                                key={index}
                                className='font-color-normal'
                                sx={{ display: 'flex', alignItems: 'top' }}
                            >
                                <ArrowRightIcon sx={{ mr: 1, }} className='font-color-call-to-action' />
                                {responsibility}
                            </Typography>
                        ))}
                    </Grid>
                    <Box sx={{ m: 1 }}>
                        {skills.map((skill, index) => (
                            <Button
                                key={index}
                                sx={{
                                    mt: 1,
                                    mr: 2,
                                    height: 20,
                                    minHeight: 20,
                                    padding: '0 8px',
                                    fontSize: '0.80rem',
                                    color: '#5FFBF1',
                                    backgroundColor: '#0A2A6B',
                                    textTransform: 'none',
                                    fontWeight: 'bold'
                                }}
                            >
                                {skill}
                            </Button>
                        ))}
                    </Box>

                </Grid>
            </Grid>
        </>
    );
}
