import { Container, useMediaQuery } from '@mui/material'
import React from 'react'
import AboutPage from './AboutPage.tsx'
import ExperiencePage from './ExperiencePage.tsx'
import ProjectPage from './ProjectPage.tsx'
// import ContactPage from './ContactPage.tsx'

export default function Home() {
    const isSmallScreen = useMediaQuery('(max-width:1050px)');
    return (
        <div>
            <Container>
                <AboutPage />
                <div id='experiencePage' style={{ marginTop: isSmallScreen ? '3rem' : '12rem' }}>
                    <ExperiencePage />
                </div>
                <div id='projectsPage' style={{ marginTop: isSmallScreen ? '3rem' : '12rem' }}>
                    <ProjectPage />
                </div>
                {/* <ContactPage /> */}
            </Container>
        </div>
    )
}
