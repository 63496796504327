import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import ExperienceCard from '../components/ExperienceCard.tsx';


export default function ExperiencePage() {
    return (
        <Grid container spacing={2} sx={{ display: 'flex', alignContent: 'flex-start', minHeight: { xs: '70vh', md: '90vh' } }} className='primary-color' id='experience'>
            <Grid item xs={12} md={4} sx={{ ml: { xs: 4, sm: 4, md: 2 }, display: 'flex', flexDirection: 'column', alignItems: { xs: 'start', md: 'center' }, justifyContent: 'center' }}>
                <Box>
                    <Typography variant='h4' className='font-color-for-topics'>Experience</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{}}>
                <ExperienceCard duration='Aug 2023 - Present' jobTitle='C# Developer | Unity Developer, ' companyName=' 3XPO - Australia' responsibilities={
                    [
                        'Design, develop, and optimise C# scripts for scalable Unity applications. Ensure high performance and maintainability within the game or application environment.',
                        'Develop and maintain robust multiplayer networking solutions.',
                        'Collaborate on new feature development and continuous improvements. Worked to design, prototype, and enhance features in alignment with project goals and technical roadmaps.',
                        'Perform in-depth debugging and performance optimisation to ensure seamless gameplay and a smooth user experience.'
                    ]} skills={['C#', 'Unity 3D', 'Version Control', 'Multiplayer Networking']} />
                <ExperienceCard duration='Jul 2022 - Aug 2023' jobTitle='Full Stack Developer & Mobile Developer, ' companyName=' AMSBB Group - Australia' responsibilities={
                    [
                        'Design, develop, and maintain cross-platform mobile applications using Flutter. Ensure the delivery of high-quality, performant mobile apps that meet user and business needs.',
                        'Upgrade, optimise, and maintain custom Flutter plugins. Implement new features and ensure seamless integration and compatibility across different versions of Flutter and its ecosystem.',
                        'Develop, enhance, and maintain web applications.',
                        'Collaborate with cross-functional teams to implement new features and improvements .Continuously enhance mobile and web applications to align with business requirements and technical roadmaps.',
                        'Identify, troubleshoot, and resolve issues across mobile and web platforms. Perform debugging and performance optimisation to ensure smooth operation and a great user experience.'
                    ]} skills={['Flutter', 'Dart', 'React.Js', 'WordPress', 'JavaScript', 'CSS', 'Version Control']} />
                <ExperienceCard duration='Jul 2020 - Apr 2022' jobTitle='Dot Net Developer, ' companyName=' Crystal Solution Pvt. Ltd - Nepal' responsibilities={
                    [
                        'Design, develop, and maintain ERP web applications and APIs for mobile platforms. Ensure high-performance, scalable solutions that seamlessly integrate with both web and mobile ecosystems.',
                        'Collaborate on new feature implementation and provide timely bug fixes',
                        'Write comprehensive unit tests for all new feature implementations. Ensure code reliability and maintainability through rigorous automated testing, adhering to best practices in software development.',
                        'Conduct code reviews and provide feedback on features developed by team members .Maintain code quality and ensure compliance with architectural standards and project guidelines through continuous review and collaboration.'

                    ]} skills={['Dot Net Core', 'MVC', 'NHibernate', 'MySQL', 'Javascript', 'CSS', 'Unit Testing', 'Version Control']} />
            </Grid>
        </Grid>
    );
}
