import React from 'react';
// import './Footer.css'; // Make sure to import the CSS file

function Footer() {
    return (
        <footer style={{ display: 'flex', justifyContent: "center" }} className="footer primary-color">
            <div className="footer-content">
                <p className="font-color-normal">© {new Date().getFullYear()} Bishal Gautam. All rights reserved.</p>
            </div>
        </footer>

    );
}

export default Footer;
