import { Box, useMediaQuery } from '@mui/material'
import React from 'react'
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/EmailRounded';

export default function SocialLinks() {
    const isSmallScreen = useMediaQuery('(max-width:1200px)');
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'row' : 'column' }}>
                <a href="https://www.linkedin.com/in/bishal-gautam-2428911a6/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <LinkedInIcon sx={{ m: 1, fontSize: isSmallScreen ? 36 : 48, cursor: 'pointer', color: 'inherit' }} />
                </a>
                <a href="mailto:bshalgautam12@gmail.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <EmailIcon sx={{ m: 1, fontSize: isSmallScreen ? 32 : 48, cursor: 'pointer', color: 'inherit' }} />
                </a>
                <a href="https://github.com/Bishal2k" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <GitHubIcon sx={{ m: 1, fontSize: isSmallScreen ? 32 : 48, cursor: 'pointer', color: 'inherit' }} />
                </a>
                {/* <a href="https://www.facebook.com/bishal.chettri.10690" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <FacebookIcon sx={{ m: 1, fontSize: isSmallScreen ? 36 : 48, cursor: 'pointer', color: 'inherit' }} />
                </a> */}

            </Box>
        </>
    )
}
