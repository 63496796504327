import React from 'react';
import { Typography, Grid } from '@mui/material';
import ProjectCard from '../components/ProjectCard.tsx';
import projectData from '../data/project_data.json';

export default function ProjectPage() {
    return (
        <Grid container spacing={2} sx={{ minHeight: { xs: '70vh', md: '90vh' }, alignContent: 'flex-start' }} className='primary-color' id='projects'>
            <Grid item xs={12} md={4} sx={{ ml: { sm: 4 }, display: 'flex', flexDirection: 'column', alignItems: { sm: 'start', md: 'center' }, justifyContent: 'center' }}>
                <Typography variant='h4' className='font-color-for-topics'>Projects</Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ ml: { sm: 4 }, display: 'flex', flexDirection: 'column', alignItems: { sm: 'start', md: 'center' }, justifyContent: 'center' }}>
                <Typography variant='h6' className='font-color-for-topics'>
                    Here are some of the projects I have contributed to
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2} sx={{ justifyContent: 'center' }}>
                {
                    projectData.map((project, index) => {
                        return (
                            <Grid key={index} item xs={10} sm={6} md={3}>
                                <ProjectCard
                                    title={project.projectTitle}
                                    imageUrl={project.imageUrl}
                                    infoUrl={project.infoUrl}
                                    shortDescription={project.shortDescription}
                                    longDescription={project.longDescription}
                                    skills={project.skills}
                                />
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
}
