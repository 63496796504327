import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button, Box, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import LinkIcon from '@mui/icons-material/Link';

interface ProjectCardProps {
  imageUrl: string;
  title: string;
  shortDescription: string;
  longDescription: string;
  infoUrl: string;
  skills: string[];
}

export default function ProjectCard({ imageUrl, title, shortDescription, longDescription, infoUrl, skills }: ProjectCardProps) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box sx={{ width: '100%', maxWidth: 345, m: 1 }}>
        <motion.div whileHover={{ scale: 1.03 }} transition={{ type: 'spring', stiffness: 300 }}>
          <Card
            onClick={handleClickOpen}
            sx={{
              width: '100%',
              height: 410,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: "#0A2A6B",
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              '&:hover': {
                boxShadow: '0 6px 12px rgba(0,0,0,0.3)',
              },
            }}
          >
            <CardMedia
              component="img"
              image={imageUrl}
              alt={title}
              sx={{
                height: 200,
                objectFit: 'cover',
                margin: '8px 8px 0 8px',
                borderRadius: '8px 8px 0 0',
              }}
            />
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '16px' }}>
              <Box>
                <Typography gutterBottom variant="h6" component="div" color="#5FFBF1" fontWeight="bold" noWrap>
                  {title}
                </Typography>
                <Typography variant="body2" color="#FFFFFF" sx={{ mb: 2, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
                  {shortDescription}
                </Typography>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 2 }}>
                  {skills.slice(0, 3).map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      size="small"
                      sx={{
                        backgroundColor: '#1E3B7E',
                        color: '#5FFBF1',
                        fontWeight: 'medium',
                      }}
                    />
                  ))}
                  {skills.length > 3 && (
                    <Chip
                      label={`+${skills.length - 3}`}
                      size="small"
                      sx={{
                        backgroundColor: '#1E3B7E',
                        color: '#5FFBF1',
                        fontWeight: 'medium',
                      }}
                    />
                  )}
                </Box>
                <Typography variant="body2" color="#5FFBF1" sx={{ alignSelf: 'flex-end', fontWeight: 'medium' }}>
                  Learn More →
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </motion.div>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: { backgroundColor: "#0A2A6B" }
        }}
      >
        <DialogTitle color="#5FFBF1" fontWeight="bold">
          {title}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#5FFBF1'
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
            <CardMedia
              component="img"
              image={imageUrl}
              alt={title}
              sx={{ width: { xs: '100%', md: '40%' }, height: 'auto', borderRadius: 1 }}
            />
            <Box>
              <Typography variant="body1" color="#FFFFFF" sx={{ mb: 2 }}>
                {longDescription}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                {skills.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill}
                    size="small"
                    sx={{
                      backgroundColor: '#1E3B7E',
                      color: '#5FFBF1',
                      fontWeight: 'medium',
                    }}
                  />
                ))}
              </Box>
              <Button
                variant="contained"
                href={infoUrl}
                target="_blank"
                rel="noopener noreferrer"
                startIcon={<LinkIcon />}
                sx={{
                  mt: 2,
                  backgroundColor: '#5FFBF1',
                  color: '#0A2A6B',
                  '&:hover': {
                    backgroundColor: '#4CD9D0',
                  },
                }}
              >
                View Project
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
