import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './components/NavBar.tsx';
import Loading from './components/Loading.tsx';
import './App.css';
import Home from './pages/Home.tsx';
import Footer from './components/Footer.tsx';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <NavBar />
          <Home />
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
