import React, { useState, useEffect } from 'react';
import { AppBar, Button, IconButton, Stack, Drawer, List, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import { HashLink as Link } from 'react-router-hash-link';
import MenuIcon from '@mui/icons-material/Menu';
import "../css/NavBar.css";

export default function NavBar() {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        const sectionIds = ['about', 'skills', 'experience', 'projects', 'contact'];
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        }, options);

        sectionIds.forEach(id => {
            const element = document.getElementById(id);
            if (element) {
                observer.observe(element);
            }
        });

        return () => observer.disconnect();
    }, []);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const handleListItemClick = () => {
        setIsExpanded(false);
    };

    return (
        <AppBar className="custom-appbar" sx={{ boxShadow: 'none' }}>
            {isSmallScreen ? (
                <>
                    <IconButton color="inherit" onClick={toggleExpanded} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor="right"
                        open={isExpanded}
                        onClose={toggleExpanded}
                    >
                        <List sx={{ height: '100%', backgroundColor: '#090446' }}>
                            <ListItem button component={Link} to="/#about" smooth onClick={handleListItemClick} className={activeSection === 'about' ? 'active-button' : 'font-color-for-topics'}>
                                <ListItemText primary="About" />
                            </ListItem>
                            <ListItem button component={Link} to="/#experience" smooth onClick={handleListItemClick} className={activeSection === 'experience' ? 'active-button' : 'font-color-for-topics'}>
                                <ListItemText primary="Experience" />
                            </ListItem>
                            <ListItem button component={Link} to="/#projects" smooth onClick={handleListItemClick} className={activeSection === 'projects' ? 'active-button' : 'font-color-for-topics'}>
                                <ListItemText primary="Projects" />
                            </ListItem>
                            {/* <ListItem button component={Link} to="/#contact" smooth onClick={handleListItemClick} className={activeSection === 'contact' ? 'active-button' : 'font-color-for-topics'}>
                                <ListItemText primary="Contact" />
                            </ListItem> */}
                        </List>
                    </Drawer>
                </>
            ) : (
                <Stack direction='row' sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: 4 }} spacing={1}>
                    <Button color='inherit' sx={{ textTransform: 'none' }} component={Link} to="/#about" smooth className={activeSection === 'about' ? 'active-button' : ''}>About</Button>
                    <Button color='inherit' sx={{ textTransform: 'none' }} component={Link} to="/#experience" smooth className={activeSection === 'experience' ? 'active-button' : ''}>Experience</Button>
                    <Button color='inherit' sx={{ textTransform: 'none' }} component={Link} to="/#projects" smooth className={activeSection === 'projects' ? 'active-button' : ''}>Projects</Button>
                    {/* <Button color='inherit' sx={{ textTransform: 'none' }} component={Link} to="/#contact" smooth className={activeSection === 'contact' ? 'active-button' : ''}>Contact</Button> */}
                </Stack>
            )}
        </AppBar>
    );
}
